import type { PossibleLocales } from '../configuration'

export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Record<PossibleLocales, Omit<ErrorProps, 'statusCode'>> = {
  'nl-BE': {
    title: 'Excuses voor het ongemak',
    subheader: 'De door jou opgevraagde pagina is niet beschikbaar.',
    buttonText: 'Home',
  },
  'fr-BE': {
    title: 'Excuses pour l’inconvénient',
    subheader: 'La page sollicitée n’est pas disponible',
    buttonText: 'Accueil',
  },
}
